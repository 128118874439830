import { usePostTrackEventWithParams } from "./analyticsQueries"

export type TReportEventTypes =
  | "Incident Report Download Clicked"
  | "Incident Report Download Initiated"
  | "Report Download Clicked"
  | "Report Download Confirmed"
  | "Report Download Initiated"
  | "Report Feedback Clicked"
  | "Report Viewed"

type TReportTypes = "basic_noise" | "filtered_noise"

function usePostReportTrackingEvent<TEventProperties>(
  event: TReportEventTypes
) {
  const postTrackEvent = usePostTrackEventWithParams<
    TReportEventTypes,
    TEventProperties
  >({
    event,
  })
  return postTrackEvent
}

export function usePostReportDownloadClicked() {
  return usePostReportTrackingEvent<{
    type: TReportTypes
  }>("Report Download Clicked")
}

export function usePostReportFeedbackClicked() {
  const postReportFeedback = usePostReportTrackingEvent(
    "Report Feedback Clicked"
  )
  return {
    ...postReportFeedback,
    mutate: () => postReportFeedback.mutate({}),
  }
}

export function usePostIncidentReportDownloadClicked() {
  const postReportFeedback = usePostReportTrackingEvent(
    "Incident Report Download Clicked"
  )
  return {
    ...postReportFeedback,
    mutate: () => postReportFeedback.mutate({}),
  }
}

export type TIncidentReportDownloadInitiatedContext =
  | "reports_page"
  | "device_page"
  | "event_log"

export function usePostIncidentReportDownloadInitiated() {
  const postReportTrackingEvent = usePostReportTrackingEvent<{
    context: TIncidentReportDownloadInitiatedContext
  }>("Incident Report Download Initiated")
  return postReportTrackingEvent
}

export function usePostReportDownloadConfirmed() {
  const postReportDownloadConfirmed = usePostReportTrackingEvent<{
    type: "filtered_noise" | "incident_report"
  }>("Report Download Confirmed")
  return postReportDownloadConfirmed
}
